.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
  height: max-content;
  min-height: unset;
  width: 100%;
  max-width: 100vw;
  color: rgb(255, 255, 255);
}

.cameraContainer {
  position: relative;
  height: 370px;
  margin-bottom: 0px;
  object-fit: cover;
  width: 100%;
}

.age-box {
  position: absolute;
  width: 100%;
  background-color: #0000005e;
  bottom: 9px;
  font-size: 72px;
  text-align: center;
  color: rgb(244, 192, 66);
  padding: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.cameraDisplay {
  height: 500px;
  max-width: 100vw;
  width: 600px;
  transform: rotateY(180);
  border: 2px solid white;
  border-radius: 10px;
  object-fit: cover !important;
}

.mirrored {
  transform: scaleX(-1);
}

.button {
  background-color: #4155c4;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button:hover,
.button:focus {
  background-color: #5d71e2;
}

.buttonContainer {
  max-width: 100vw;
  display: flex;
  gap: 10px;
  margin-top: 0px;
  flex-wrap: wrap;
  justify-content: center;
}
.container video {
  height: 320px;
  width: 100%;
}
.listText {
  display: flex;
  flex-wrap: wrap;
}
.listText div {
  width: 50%;
  margin: 10px 0px;
}

.enrollDisplay{
  position: absolute;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  left: 2px;
  top: 47px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background-color: rgba(0,0,0,0.6);
}

.enrollDisplay span{
  color: #ffffff;
  padding: 5px;
  font-size: 20px;
  font-weight: 600;
}

.cameraFrame {
  width: 90%;
  height: 250px;
  position: absolute;
  top: 56%;
  z-index: 1;
  border: 4px solid;
  left: 50.5%;
  transform: translate(-50%, -50%);
  border-radius: 14px;
}

.cameraFrame img {
  opacity: 0.5;
  height: 100%;
  width: 100%;
}

.cameraFrameOuter{
  width: 100%;
  height: 87%;
  position: absolute;
  top: 46px;
  border-radius: 10px;
  left: 1px;
  border: 1px solid #000;
  z-index: 1;
  background: rgba(0,0,0,0.5);
  clip-path: polygon(0% 0%, 0% 100%, 5% 100%, 5% 15%, 95% 15%, 95% 86.5%, 0% 86.5%, 0% 100%, 100% 100%, 100% 0%);
}

@media only screen and (max-width: 767px) {
  .container video {
    height: 350px;
    width: 100%;
  }
  .cameraContainer{
    height: 400px;
  }
  /*@supports (-webkit-touch-callout: none) {*/
  /*  .cameraDisplay{*/
  /*    object-fit: fill !important;*/
  /*  }*/
  /*}*/
}

@media only screen and (max-width: 360px) { 
  .container video {
    height: 318px;
  }
  .container canvas {
    height: 318px !important;
  }
  .cameraFrameOuter {
    height: 84%;
  }
  .cameraFrame {
    height: 199px;
  }
  .cameraContainer{
    height: 376px;
  }
}

@media (orientation: landscape) {
  @media only screen and (max-width: 1024px) {
    /* .cameraFrameOuter {
      height: 97%;
      top: 1px;
      clip-path: polygon(0% 0%, 0% 100%, 5% 100%, 5% 13.5%, 95% 13.5%, 95% 90.5%, 0% 90.5%, 0% 100%, 100% 100%, 100% 0%);
    }
    .enrollDisplay{
      top: 2px;
    }
    .cameraFrame{
      top: 50%;
      height: 213px;
    }
    /* .container video {
      height: 230px;
    } */
  }
}