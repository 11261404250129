@font-face {
  font-family: "Helvetica, Arial, sans-serif";
  src: url("./assets/fonts/Helvetica-Bold.ttf");
}
@font-face {
  font-family: "Helvetica, Arial, sans-serif";
  src: url("./assets/fonts/Helvetica.ttf");
}
@font-face {
  font-family: "Helvetica, Arial, sans-serif";
  src: url("./assets/fonts/Helvetica-light.ttf");
}

body {
  font-family: Helvetica, Arial, sans-serif;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.homeBG {
  background-image: url("./assets/home-bg.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.MuiLinearProgress-colorPrimary {
  height: 14px !important;
  border-radius: 10px !important;
}

.MuiPickersPopper-root {
  z-index: 99999999 !important;
}

.homePageWrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}

.instructionOverlay {
  position: absolute;
  width: 100.6%;
  left: 0;
  top: 0;
  height: 87.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.homeSidebarImg {
  bottom: 8rem;
  max-width: 400px;
  position: fixed;
  right: 57px;
  z-index: 99999;
}

.homeSidebarImg img {
  width: 100%;
  height: 100%;
}

.documentCamera .videoCamera {
  height: 343px !important;
}

.MuiLinearProgress-bar {
  background-color: green !important;
}
.MuiLinearProgress-root {
  background-color: rgb(0 128 0 / 20%) !important;
}

.green-border {
  border: 3px solid #2ba24c;
  height: 187px;
  width: 165px;
  position: absolute;
  right: 5rem;
  bottom: 27rem;
  z-index: 1;
}

.green-border p {
  color: #2ba24c;
  margin: 0px;
  position: absolute;
  bottom: -27px;
  left: 0;
  width: 100%;
  text-align: center;
}

.datePickerWrap > div {
  width: 100%;
}

.videoCamera {
  width: 100% !important;
}

.AgeItem {
  transition: all 0.5s;
  justify-content: center !important;
  z-index: 99999999;
}
.AgeItem:last-child {
  border: none !important;
}
.AgeItem:hover {
  background-color: #1194ff !important;
  color: #fff !important;
}

.overlayLoader {
  position: absolute;
  width: 100.6%;
  left: 0px;
  top: 0px;
  height: 80%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.overlayLoader span {
  color: #fff !important;
}

.cameraSelect {
  /*width: 50% !important;*/
}

.cameraSelect .MuiSelect-select {
  padding: 10px 13px !important;
  padding-right: 36px !important;
}

.overflowUnset {
  overflow: unset !important;
}

.googleBackground {
  position: absolute;
}

/* Spinner Loader */
.loader {
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}
@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.6), 2.5em 0em 0 0em rgba(0, 0, 0, 0.6),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.6), 0em 2.5em 0 0em rgba(0, 0, 0, 0.6),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.6),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(0, 0, 0, 0.6),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.6), 0em 2.5em 0 0em rgba(0, 0, 0, 0.6),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.6), -2.6em 0em 0 0em rgba(0, 0, 0, 0.6),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.6), 0em 2.5em 0 0em rgba(0, 0, 0, 0.6),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.6), -2.6em 0em 0 0em rgba(0, 0, 0, 0.6),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.6);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.6),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(0, 0, 0, 0.6), -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.6),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.6),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.6);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.6),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.6),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.6), -2.6em 0em 0 0em rgba(0, 0, 0, 0.6),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.6);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.6),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.6), 2.5em 0em 0 0em rgba(0, 0, 0, 0.6),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.6),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.6);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.6),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.6), 2.5em 0em 0 0em rgba(0, 0, 0, 0.6),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.6),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.6);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.6),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.6), 2.5em 0em 0 0em rgba(0, 0, 0, 0.6),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.6), 0em 2.5em 0 0em rgba(0, 0, 0, 0.6),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

/* Spinner Loader End */

.success {
  animation: successAnimation 3s normal;
}
@keyframes successAnimation {
  from {background-color: green;}
  to {background-color: #fff;}
}

.failure {
  animation: failAnimation 3s normal;
}
@keyframes failAnimation {
  from {
    background-color: #dc1431;
  }
  to {
    background-color: #fff;
  }
}

@media only screen and (max-width: 767px) {
  .homeSidebarImg {
    display: none;
  }
  .homeBG{
    background-image: unset;
  }
  .green-border {
    display: none;
  }
  .overlayLoader {
    height: 76%;
  }
  .instructionOverlay {
    height: 88%;
  }
  br {
    content: "";
  }
  .cameraSelect {
    width: 234px !important;
  }
  .cameraSelect .MuiSelect-select {
    padding: 4px 13px !important;
  }
  .mainCameraSelectWrap {
    margin-bottom: 5px !important;
    margin-top: 8px !important;
  }
  .cardHeadingNoMargin {
    margin-left: 0px !important;
  }
  .headerLogo {
    width: 140px;
  }
  .homePageWrapper {
    height: unset;
    padding-top: 80px;
  }
  body {
    background-attachment: fixed;
  }
  .homeComponent {
    height: calc(100vh - 160px);
  }
  .homeComponent > div {
    height: 100%;
  }
  .centralLogo {
    width: 100px;
    margin-left: auto;
  }
}

@media only screen and (max-width: 360px) {
  .documentCamera .videoCamera {
    height: 275px !important;
    margin-top: -4px;
  }
  .tab-inner {
    min-width: unset !important;
    padding: 12px 4px !important;
    max-width: 91px !important;
    margin-right: 0px !important;
  }
  #full-width-tabpanel-0 > div,
  #full-width-tabpanel-1 > div,
  #full-width-tabpanel-2 > div,
  #full-width-tabpanel-3 > div {
    padding: 10px 10px;
  }
  #full-width-tabpanel-0 svg {
    width: 97px;
    height: 97px;
  }
  #canvasInput {
    margin-top: -15px;
  }
}

@media only screen and (max-width: 1400px) {
  .homeSidebarImg {
    height: 650px;
  }
  .green-border {
    right: 1rem;
    bottom: 21rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1400px) {
  .homeSidebarImg {
    max-width: 300px;
    height: unset;
    right: 14px;
    /* height: 400px; */
  }
}

@media only screen and (min-width: 1800px) {
  .homeSidebarImg {
    left: 50%;
    width: 1536px;
    transform: translateX(-48%);
    right: 0px;
    max-width: unset;
  }
  .homeSidebarImg img {
    width: 400px;
    display: block;
    margin-left: auto;
    margin-right: -40px;
  }
}

@media (orientation: landscape) {
  @media only screen and (max-width: 1024px) {
    /* #canvasInput canvas {
      height: 230px !important;
    }
    .completeOverlay {
      height: 50% !important;
    }
    .documentCamera .videoCamera {
      height: 270px !important;
    }
    .documentCamera .mainCameraSelectWrap{
      position: absolute;
      z-index: 2;
      background-color: #fff;
      width: 57% !important;
      top: 30px;
    }
    .documentCamera .mainCameraSelectWrap label{
      display: none;
    } */
    .completeOverlay {
      height: 74% !important;
    }
  }
}